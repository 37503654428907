@media (min-width:$widthWide){
	.imagePicture{
		--imagePictureLoading:250px;
	}
	.imageTitle,.imageLikesContainer{
		$lines: 2;
		--ImageCapsMaxHeight: calc(#{$lines} * 25px);
		--ImageCapsPaddingLeft: 3px;
		--ImageCapsPaddingTop: 2px;
		--ImageCapsFontSize: 18px;
	}
	.dogLikesImg{
		--dogLikesImgFlexBasis: 45px;
	}
	.dogLikesCount{
		--dogsLikeCountPadding:4px;
	}

	.multipleImageIcon{
		--multipleImageTop:35px;
		--multipleImageRight:5px;
		--multipleImageSize:30px;
	}

}
@media (max-width:($widthWide - 1)) and (min-width:$widthNarrow){
	.imagePicture{
		--imagePictureLoading:250px;
	}
	.imageTitle,.imageLikesContainer{
		$lines: 2;
		--ImageCapsMaxHeight: calc(#{$lines} * 20px);
		--ImageCapsPaddingLeft: 3px;
		--ImageCapsPaddingTop: 2px;
		--ImageCapsFontSize: 14px;
	}
	.dogLikesImg{
		--dogLikesImgFlexBasis: 40px;
	}
	.dogLikesCount{
		--dogsLikeCountPadding:4px;
	}
	.multipleImageIcon{
		--multipleImageTop:28px;
		--multipleImageRight:4px;
		--multipleImageSize:28px;
	}
}
@media (max-width:($widthNarrow - 1)){
	.imagePicture{
		--imagePictureLoading:100px;
	}
	.imageTitle,.imageLikesContainer{
		$lines: 2;
		line-height: 1.2;
		--ImageCapsMaxHeight: calc(#{$lines} * 20px);
		--ImageCapsPaddingLeft: 4px;
		--ImageCapsPaddingTop: 2px;
		--ImageCapsFontSize: 12px;
	}
	.dogLikesImg{
		--dogLikesImgFlexBasis: 35px;
	}
	.dogLikesCount{
		--dogsLikeCountPadding:2px;
	}
	.multipleImageIcon{
		--multipleImageTop:20px;
		--multipleImageRight:2px;
		--multipleImageSize:25px;
	}
}
