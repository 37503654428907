/*Orange*/
/*Orange Light*/
/*Navy Blue */
/*Navy Blue Light*/
/*white text color*/
@media (min-width: 1000px) {
  .imagePicture {
    --imagePictureLoading:250px;
  }

  .imageTitle, .imageLikesContainer {
    --ImageCapsMaxHeight: calc(2 * 25px);
    --ImageCapsPaddingLeft: 3px;
    --ImageCapsPaddingTop: 2px;
    --ImageCapsFontSize: 18px;
  }

  .dogLikesImg {
    --dogLikesImgFlexBasis: 45px;
  }

  .dogLikesCount {
    --dogsLikeCountPadding:4px;
  }

  .multipleImageIcon {
    --multipleImageTop:35px;
    --multipleImageRight:5px;
    --multipleImageSize:30px;
  }
}
@media (max-width: 999px) and (min-width: 600px) {
  .imagePicture {
    --imagePictureLoading:250px;
  }

  .imageTitle, .imageLikesContainer {
    --ImageCapsMaxHeight: calc(2 * 20px);
    --ImageCapsPaddingLeft: 3px;
    --ImageCapsPaddingTop: 2px;
    --ImageCapsFontSize: 14px;
  }

  .dogLikesImg {
    --dogLikesImgFlexBasis: 40px;
  }

  .dogLikesCount {
    --dogsLikeCountPadding:4px;
  }

  .multipleImageIcon {
    --multipleImageTop:28px;
    --multipleImageRight:4px;
    --multipleImageSize:28px;
  }
}
@media (max-width: 599px) {
  .imagePicture {
    --imagePictureLoading:100px;
  }

  .imageTitle, .imageLikesContainer {
    line-height: 1.2;
    --ImageCapsMaxHeight: calc(2 * 20px);
    --ImageCapsPaddingLeft: 4px;
    --ImageCapsPaddingTop: 2px;
    --ImageCapsFontSize: 12px;
  }

  .dogLikesImg {
    --dogLikesImgFlexBasis: 35px;
  }

  .dogLikesCount {
    --dogsLikeCountPadding:2px;
  }

  .multipleImageIcon {
    --multipleImageTop:20px;
    --multipleImageRight:2px;
    --multipleImageSize:25px;
  }
}
#ColumnContainer {
  display: flex;
  margin-left: 10px;
}

.imageColumnContainer {
  padding-right: 10px;
}

.imageColumn {
  width: 100%;
}

.imageColumnBottom {
  height: 25px;
}

.imageContainer {
  display: block;
  border: solid cyan 0px;
  position: relative;
  overflow: hidden;
  border-radius: 0px 4px 4px;
  margin-bottom: 10px;
  background-color: #3c4357;
}

.multipleImageIcon {
  position: absolute;
  top: var(--multipleImageTop);
  right: var(--multipleImageRight);
  width: var(--multipleImageSize);
  height: var(--multipleImageSize);
}

.imagePicture img.loading {
  height: var(--imagePictureLoading);
}
.imagePicture img, .imagePicture source, .imagePicture video {
  width: 100%;
  height: auto;
}

.imageTitle, .imageLikesContainer {
  max-height: var(--ImageCapsMaxHeight);
  padding-left: var(--ImageCapsPaddingLeft);
  padding-top: var(--ImageCapsPaddingTop);
  font-size: var(--ImageCapsFontSize);
}

.imageTitle {
  white-space: nowrap;
  overflow: hidden;
}

.imageLikesContainer {
  display: flex;
}
.imageLikesContainer .dogLikesImg, .imageLikesContainer .dogLikesCount {
  height: 100%;
}
.imageLikesContainer .dogLikesImg {
  flex-shrink: 1;
  flex-basis: var(--dogLikesImgFlexBasis);
  cursor: pointer;
}
.imageLikesContainer .dogLikesImg:hover {
  border: solid green 1px;
}
.imageLikesContainer .DogPatsImg.liked, .imageLikesContainer .DogBoopsImg.liked {
  background-color: #00379c;
}
.imageLikesContainer .dogLikesCount {
  flex: 1;
  padding-left: var(--dogsLikeCountPadding);
}